import React from 'react';
import { useSelector } from 'react-redux';
import Ueberuns from './Ueberuns';
import UeberUnsOldFashion from './UeberUnsOldFashion';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';

function SwitchUeberUns() {
    const markenStandort = useSelector(markenStandortSelector);
    if (markenStandort.isTechno || !markenStandort.implementsAreaConcept) {
        return <UeberUnsOldFashion />;
    }
    return <Ueberuns standortUrl={markenStandort.url} />;
}

export default SwitchUeberUns;
