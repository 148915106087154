import React from 'react';
import { useSelector } from 'react-redux';

import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import Image from '../../components/Image/Image';
import loadable from '@loadable/component';
import Button from '../../components/form-elements/Button';
import LegacyShopWrapper from '../../LegacyShopWrapper';

// TODO: use new Modal component

const ServiceStationFinder = loadable(() => import('../../components/ServiceStationFinder/ServiceStationFinder'));

function UeberUnsOldFashion() {
    const markenStandort = useSelector(markenStandortSelector);
    return (
        <>
            <LegacyShopWrapper>
                <div>
                    <div style={{ clear: 'both' }} />
                    <div className="ueber_uns_wrapper">
                        <div className="header_container">
                            <div className="headline_container">
                                <p className="bold_headline">AUTOTEILE PLUS SERVICE -</p>
                                <p className="thin_headline">
                                    EIN ANGEBOT VON{' '}
                                    {markenStandort.isTechno ? (
                                        <span>DEUTSCHLANDS FÜHRENDEN AUTOHÄUSERN.</span>
                                    ) : (
                                        <span>{markenStandort.anschrift.firma}</span>
                                    )}
                                </p>
                                <h3>
                                    <b>Unser Prinzip: Sowohl Markenwerkstatt-Vermittlung als auch Shopping!</b>
                                </h3>
                                <p className="text">
                                    <b>AUTOTEILE PLUS SERVICE</b> ist ein markenübergreifender Zusammenschluss
                                    <br /> der 150 größten Autohändler Deutschlands mit bundesweit über 1.800 Autohäusern. Bei uns können
                                    Sie Original-Ersatzteile, Zubehör oder Accessoires&nbsp;für Ihr Fahrzeug
                                    <b>sowohl</b> nach Hause bestellen <b>oder</b> zur Abholung im nächsten Autohaus vorreservieren,
                                    <b>als auch</b> eine auf Ihr Fahrzeug spezialisierte&nbsp;Markenwerkstatt in Ihrer Nähe finden, Ihren
                                    Wunschtermin vereinbaren und vom fachgerechten Service zum vereinbarten Festpreis profitieren.
                                </p>
                            </div>
                            <div className="image_container">
                                <Image
                                    src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/UeberUnsMeister.png`}
                                    alt=""
                                />
                            </div>
                            <div className="clear" />
                        </div>
                        <div className="section_container">
                            <div className="heading_container">
                                <i className="fa fa-check-circle" />
                                <p>
                                    {' '}
                                    WORAUF SIE SICH BEI <b>AUTOTEILE PLUS SERVICE</b> VERLASSEN KÖNNEN
                                </p>
                                <div className="clear" />
                            </div>
                            <div className="row_container">
                                <div className="content_container" id="ersatzteile">
                                    <div className="inner">
                                        <div className="image_box">
                                            <Image
                                                src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Matrix1.jpg`}
                                                alt=""
                                            />
                                        </div>
                                        <div className="text_box">
                                            <div className="text_inner">
                                                <p>
                                                    <b>
                                                        IHR PLUS AN QUALITÄT:
                                                        <br />
                                                        BEI UNS - NUR 100% ORIGINAL-ERSATZTEILE.
                                                    </b>
                                                    <br />
                                                    <br />
                                                    Bei <b>AUTOTEILE PLUS SERVICE</b> können Sie sich darauf verlassen, dass ausschließlich
                                                    Original-Ersatzteile vertrieben - und im Fall der Fälle eingebaut werden. Was anderes
                                                    kommt bei Deutschlands führenden Autohäusern nicht in Frage.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content_container">
                                    <div className="inner">
                                        <div className="image_box">
                                            <Image
                                                src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Matrix2.jpg`}
                                                alt=""
                                            />
                                        </div>
                                        <div className="text_box">
                                            <div className="text_inner">
                                                <p>
                                                    <b>
                                                        IHR PLUS AN VERLÄSSLICHKEIT:
                                                        <br />
                                                        UNSERE FESTPREISE.
                                                    </b>
                                                    <br />
                                                    <br />
                                                    Egal ob Wartung, Inspektion, HU-Termin oder der Einbau eines von Ihnen bestellten
                                                    Ersatzteils oder Zubehörs: Bei <b>AUTOTEILE PLUS SERVICE</b> müssen Sie in Sachen Kosten
                                                    hinterher niemals mit unangenehmen Überraschungen rechnen. Der vorher online vereinbarte
                                                    Fest- oder Paketpreis ist 100%ig bindend.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content_container">
                                    <div className="inner">
                                        <div className="image_box">
                                            <Image
                                                src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Matrix3.jpg`}
                                                alt=""
                                            />
                                        </div>
                                        <div className="text_box">
                                            <div className="text_inner">
                                                <p>
                                                    <b>
                                                        IHR PLUS AN VERTRAUEN:
                                                        <br />
                                                        UNSERE QUALITÄTSTANDARDS.
                                                    </b>
                                                    <br />
                                                    <br />
                                                    Welche Experten Sie bei <b>AUTOTEILE PLUS SERVICE</b> für Ihr Fahrzeug benötigen: Sie
                                                    können sich sicher sein, dass sich Ihr Fahrzeug in absolut kompetenten Händen befindet.
                                                    Schließlich handelt es sich dabei garantiert um eines von Deutschlands führenden
                                                    Autohäusern, dessen angeschlossene Markenwerkstatt sich ausschließlich auf die jeweils
                                                    geführten Fahrzeughersteller und -modelle spezialisiert hat.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content_container">
                                    <div className="inner">
                                        <div className="image_box">
                                            <Image
                                                src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Matrix4.jpg`}
                                                alt=""
                                            />
                                        </div>
                                        <div className="text_box">
                                            <div className="text_inner">
                                                <p>
                                                    <b>
                                                        IHR PLUS AN SICHERHEIT:
                                                        <br />
                                                        UNSER BLICK AUFS GANZE.
                                                    </b>
                                                    <br />
                                                    <br />
                                                    Es gibt nur wenige Teile und Komponenten, die es bei
                                                    <b>AUTOTEILE PLUS SERVICE</b> nicht gibt. Und wenn, dann aus gutem Grund. So verkaufen
                                                    wir zum Beispiel keine sicherheitsrelevanten Komponenten wie Bremsscheiben oder
                                                    Achsmanschetten einzeln, sondern nur in Verbindung mit der entsprechenden
                                                    Service-Leistung in einer unserer Markenwerkstätten. Für ein Höchstmaß Sicherheit.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content_container" id="zubehoerauswahl">
                                    <div className="inner">
                                        <div className="image_box">
                                            <Image
                                                src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Matrix5.jpg`}
                                                alt=""
                                            />
                                        </div>
                                        <div className="text_box">
                                            <div className="text_inner">
                                                <p>
                                                    <b>
                                                        IHR PLUS AN VIELFALT:
                                                        <br />
                                                        UNSER BREITES ZUBEHÖR-SORTIMENT
                                                    </b>
                                                    <br />
                                                    <br />
                                                    Reifen, Felgen, Kindersitze oder Dachbox, Navi oder Marderabwehr - unser umfangreiches
                                                    Sortiment lässt kaum einen Wunsch rund um Ihr Auto unerfüllt. Das Beste dabei: bei{' '}
                                                    <b>AUTOTEILE PLUS SERVICE</b>
                                                    können Sie sich jederzeit auf das Know-How und die Empfehlung einer, auf Ihr Auto
                                                    spezialisierten Markenwerkstatt verlassen. <br />
                                                    <br /> Kurz: überzeugendes Sortiment, zuverlässige Montage und freundlicher Service -
                                                    alles aus einer Hand.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content_container" id="infografik">
                                    <div className="inner">
                                        <div className="image_box">
                                            <div className="infografik">
                                                <Image
                                                    alt=""
                                                    src="http://techno-images.s3.eu-central-1.amazonaws.com/images/UeberUns/Infografik-Teaser.jpg"
                                                />
                                                <Button data-uk-toggle="target: #ModalInfografik">zur infografik</Button>
                                                <div id="ModalInfografik" data-uk-modal="">
                                                    <div className="uk-modal-dialog uk-margin-auto-vertical">
                                                        <button
                                                            className="uk-modal-close modalInfografik_button"
                                                            style={{ border: 0, background: 'transparent' }}
                                                            type="button"
                                                        >
                                                            <i className="fa fa-2x fa-times" />
                                                        </button>
                                                        <Image
                                                            className="uk-width-1-1"
                                                            src="http://techno-images.s3.eu-central-1.amazonaws.com/images/UeberUns/Infografik.jpg"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text_box">
                                            <div className="text_inner">
                                                <p>
                                                    Bei <b>AUTOTEILE PLUS SERVICE</b>
                                                    &nbsp;profitieren Sie immer von Fullservice: <br />
                                                    <br />
                                                    Online stehen wir 24 Stunden an 365 Tagen im Jahr zu Ihrer Verfügung. Vor Ort sind wir
                                                    bundesweit und markenübergreifend in 1.800 Autohäusern für Sie da. Immer mit bester
                                                    Qualität, hoher Teileverfügbarkeit, sicherer Bezahlung sowie kurzen Lieferzeiten.
                                                    <br />
                                                    <br />
                                                    Bestellen Sie online Ihr gewünschtes Autozubehör oder reservieren Sie das benötigte
                                                    Ersatzteil und vereinbaren Sie Termine für Service, Reparatur oder Einbau in einer
                                                    unserer Fachwerkstätten. Mithilfe Ihrer Automarke und Postleitzahl ermitteln wir für Sie
                                                    eine Auswahl von zertifizierten Autohäusern in Ihrer Nähe.
                                                    <br />
                                                    <br />
                                                    Sollten sich in Ihrer Region keine passende Niederlassung befinden, suchen wir
                                                    automatisch im erweiterten Umkreis, sodass Ihnen immer die nächsten Autowerkstätten
                                                    Ihrer Marke angezeigt werden.
                                                    <br />
                                                    <br />
                                                    Wählen Sie ganz bequem nur noch Ihre gewünschte Fachwerkstatt und Ihren benötigten
                                                    Service aus. Unsere Autohaus-Partner beraten Sie umfassend und kompetent online,
                                                    telefonisch und vor Ort.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LegacyShopWrapper>
            {markenStandort.isTechno && (
                <section className="uk-section uk-section-default uk-section-small">
                    <div className="uk-container">
                        <ServiceStationFinder />
                    </div>
                </section>
            )}
        </>
    );
}

export default UeberUnsOldFashion;
