import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/form-elements/Button';
import Modal from '../../components/Modal/Modal';
import { InternalLink } from '../../components/form-elements/Link';
import { navigateToSubLocation } from '../../../modules/actions/standort/subLocation';
import { useStandortHistory } from '../../components/VHost/StandortHistory';
import LocationCard from '../../components/LocationLists/LocationCard';
import { standortUrlSelector } from '../../../modules/selectors/url';

function MoreLocationsModal({ areaShops }) {
    const standortUrl = useSelector(standortUrlSelector);
    const [open, setOpen] = useState(false);
    const history = useStandortHistory();

    return (
        <div className="uk-flex-right@m uk-flex uk-flex-center@s uk-width-expand@m">
            {standortUrl !== 'shop-auto-jakob' && (
                <Button onClick={() => setOpen(true)} modifier="link">
                    weitere Standorte
                </Button>
            )}
            <Modal isOpen={open} onRequestClose={() => setOpen(false)} fullWidth>
                <Button modifier="text" className="uk-modal-close-default" onClick={() => setOpen(false)}>
                    <i data-uk-icon="close" />
                </Button>
                <div className="uk-modal-header">
                    <h2 className="uk-modal-title">Unsere Standorte</h2>
                </div>
                <div className="uk-modal-body">
                    {areaShops.map((areaShop, index) => (
                        <Fragment key={index}>
                            <h3>
                                <InternalLink to="/" newStandortUrl={areaShop.url}>
                                    RAUM: {areaShop.areaTitle}
                                </InternalLink>
                            </h3>
                            <div className="uk-grid uk-grid-small uk-child-width-1-2@s" data-uk-grid="">
                                {areaShop.areaShopSubLocations.map(areaShopSubLocation => (
                                    <div key={areaShopSubLocation._id}>
                                        <Button
                                            modifier="link"
                                            fullWidth
                                            onClick={() => {
                                                setOpen(false);
                                                navigateToSubLocation({
                                                    history: history,
                                                    subLocationUrl: areaShopSubLocation.subLocationUrl,
                                                    standortUrl: areaShop.url,
                                                });
                                            }}
                                        >
                                            <LocationCard location={areaShopSubLocation} />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Fragment>
                    ))}
                </div>
            </Modal>
        </div>
    );
}

export default MoreLocationsModal;
