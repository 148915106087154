import React from 'react';
import { ContactPhone } from '../Meisterkachel/Contact';
import Button from '../form-elements/Button';
import ContactModal from '../Anfrage/ContactModal';

function ContactRow({ location, additionalActions }) {
    return (
        <section className="uk-section uk-section-default uk-section-small">
            <div className="uk-container">
                <h2>Öffnungszeiten & Kontakt</h2>
                <div className="uk-grid uk-grid-small uk-child-width-1-3@s uk-margin" data-uk-grid="">
                    {location.anschrift && (
                        <dl className="uk-description-list">
                            <dt>Kontakt</dt>
                            <dd>
                                {location.anschrift.firma} <br />
                                {location.anschrift.strasse} <br />
                                {`${location.anschrift.plz} ${location.anschrift.ort}`}
                            </dd>
                        </dl>
                    )}
                    {location.hotline && (
                        <div>
                            <dl className="uk-description-list">
                                <dt>SERVICE</dt>
                                <dd>{location.hotline}</dd>
                            </dl>
                        </div>
                    )}
                    {location.oeffnungszeiten && (
                        <div>
                            <dl className="uk-description-list">
                                <dt>VERKAUF</dt>
                                <dd>{location.oeffnungszeiten}</dd>
                            </dl>
                        </div>
                    )}
                </div>
                <div
                    className="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-auto@s uk-flex uk-flex-center uk-flex-left@m"
                    data-uk-grid=""
                >
                    <div>
                        <ContactPhone modifier="default" hideArrow phone={location.anschrift.telefon} />
                    </div>
                    <div>
                        <ContactModal>
                            {({ openModal }) => (
                                <Button
                                    modifier="default"
                                    hideArrow
                                    fullWidth
                                    className="uk-flex uk-flex-left uk-flex-middle uk-margin-small-bottom"
                                    onClick={openModal}
                                >
                                    <i className="uk-margin-small-right" data-uk-icon="mail" /> {location.anschrift.email}
                                </Button>
                            )}
                        </ContactModal>
                    </div>
                    {additionalActions}
                </div>
            </div>
        </section>
    );
}

export default ContactRow;
