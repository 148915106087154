import React, { useState } from 'react';
import Button from '../form-elements/Button';
import LocationCardsWrapper from './LocationCardsWrapper';
import LocationCard from './LocationCard';
import { navigateToSubLocation } from '../../../modules/actions/standort/subLocation';
import { useStandortHistory } from '../VHost/StandortHistory';

export function carMakesString(carMakes = []) {
    return carMakes.join(', ').replace(/, ([^,]*)$/, ' und $1');
}

function LocationRow({ areaShop }) {
    const [showLocationList, setShowLocationList] = useState(true);
    const history = useStandortHistory();

    return (
        <li>
            <Button
                modifier="link"
                fullWidth
                onClick={() => {
                    setShowLocationList(prev => !prev);
                }}
            >
                <div className="uk-tile uk-tile-muted uk-padding-small uk-text-left">
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <div className="uk-flex uk-flex-column">
                            <div className="uk-text-bold">Unsere Center im Überblick</div>
                        </div>
                        <i data-uk-icon="icon: chevron-double-right; ratio: 2" />
                    </div>
                </div>
            </Button>

            {showLocationList && (
                <LocationCardsWrapper>
                    {areaShop.areaShopSubLocations.map(areaShopSublocation => (
                        <div key={areaShopSublocation._id}>
                            <LocationCard
                                location={areaShopSublocation}
                                footerChildren={
                                    <Button
                                        modifier="secondary"
                                        fullWidth
                                        onClick={() => {
                                            navigateToSubLocation({
                                                history,
                                                subLocationUrl: areaShopSublocation.subLocationUrl,
                                                standortUrl: areaShop.url,
                                            });
                                        }}
                                    >
                                        MEHR INFOS
                                    </Button>
                                }
                            />
                        </div>
                    ))}
                </LocationCardsWrapper>
            )}
        </li>
    );
}

function LocationRows({ areaShops }) {
    return (
        <ul className="uk-list">
            {areaShops.map(areaShop => (
                <LocationRow key={areaShop._id} areaShop={areaShop} />
            ))}
        </ul>
    );
}

export default LocationRows;
